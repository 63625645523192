<template>
  <div unlock-balance>
    <template v-if="!showConfirm">
      <p>{{ $t('_.headerDesc') }}</p>
      <div class="container">
        <div class="all">
          <CheckboxItem v-model="checkedAll" theme="dark" @change="updateAllCheckbox">
            <p><b>{{ $t('_.selectAll') }}</b></p>
            <p>{{ $t('_.fullyAwareAndAgree') }}</p>
          </CheckboxItem>
        </div>
        <div class="scroll-box">
          <div>
            <ul>
              <li v-for="item in checkList">
                <CheckboxItem theme="dark" v-model="item.checked" @change="updateCheckbox"><span v-html="$t(item.text, item.textOption)" /></CheckboxItem>
              </li>
            </ul>
            <p>{{ $t('_.ifYouDoNotAgree') }}</p>
          </div>
        </div>
      </div>
      <p>{{ $t('_.footerDesc') }}</p>
      <div class="btn-holder">
        <ProgressButton button-name="onBoarding.unlockBalance.returnToPlay" :disabled="!checkedAll" @click="returnToPlay" />
        <ProgressButton button-name="onBoarding.unlockBalance.extendTimeOut" class="gray" @click="extendTimeOut" />
      </div>
    </template>

    <div v-else class="confirm">
      <div class="content">
        <FontIcon name="check-over-circle" />
        <h2>{{ $t('success') }}</h2>
        <div>
          <p>{{ $t('onBoarding.unlockBalance.confirmMessage1') }}</p>
          <p>{{ $t('onBoarding.unlockBalance.confirmMessage2') }}</p>
        </div>
      </div>
      <div class="btn-holder">
        <ProgressButton button-name="confirm" @click="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import { redirectRGUrl } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import ColorButton from '@shared/components/common/ColorButton.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';

export default {
  name: 'UnlockBalance',
  lexicon: 'onBoarding.unlockBalance',
  components: { ProgressButton, ColorButton, CheckboxItem, FontIcon },
  data() {
    return {
      showConfirm: false,
      checkedAll: false,
      checkList: [
        { text: '_.checkItem1', checked: false },
        { text: '_.checkItem2', checked: false },
        { text: '_.checkItem3', checked: false },
        { text: '_.checkItem4', textOption: { tncLink: 'https://ggpoker.co.uk/terms-conditions', houseRulesLink: 'https://ggpoker.co.uk/house-rules', policyLink: 'https://ggpoker.co.uk/security-ecology-policy' }, checked: false },
      ],
    };
  },
  computed: {
    site: state('env', 'site'),
  },
  methods: {
    updateAllCheckbox() {
      if (this.checkedAll) this.checkList.forEach(item => item.checked = true);
      else this.checkList.forEach(item => item.checked = false);
    },
    updateCheckbox() {
      const isConfirmAll = this.checkList.findIndex(item => item.checked === false) < 0;
      this.checkedAll = isConfirmAll;
    },
    extendTimeOut() {
      redirectRGUrl(this, this.site);
    },
    async returnToPlay() {
      await this.$services.onboarding.setReleaseLockBalanceReturnFromTimeout();
      this.showConfirm = true;

    },
    close() {
      close(this);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[unlock-balance] {
  [checkbox-item] {
    i { .br(2); }
    em { white-space: initial; text-overflow: initial; word-wrap: initial; word-break: initial; }
    a { .c(#c64040); .underline(); }
  }
}
</style>
<style scoped lang="less">
@import '~@/less/proj.less';
[unlock-balance] { .p(40, 28); .c(#ccc);
  .container { .mv(24); .p(24, 4); .br(8); .-a(#333); .bgc(#262626); .fs(15); .crop();
    .all { .ph(16);
      &::after {
        .cnt(); .wf(); .h(2); .mv(16); .bgc(#7f7f7f);
      }
    }
    .scroll-box { .ph(16); .scroll(); .max-h(200);
      &::-webkit-scrollbar-thumb { .mh(2); .br(2em); .bgc(#7f7f7f); }
      &::-webkit-scrollbar { .w(6); }
      ul { .mb(16); }
      li + li { .mt(8); }
    }
  }
  [progress-button] {
    + [progress-button] { .mt(12); }
    &.gray { .bgc(#717171);
      &:hover { .bgc(#858585); }
    }
    &:disabled {
      &.gray { .-a(#717171, 2); .bgc(transparent); .o(1); .c(#717171);
        &:hover { border-width: 1px; .bgc(transparent); }
      }
    }
  }

  .confirm { .tc();
    [font-icon] { .p(16); .fs(50); .c(#1db77d); }
    h2 { .fs(32); .mt(12); .regular(); .c(#fff); .mb(28); }
    .content { flex: 1; }
    [progress-button] { .mt(56); }
  }
}
</style>
